import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Marker, GoogleMap, OverlayView, useLoadScript } from "@react-google-maps/api";

const NewMap = (props) => {
  const [searchParams] = useSearchParams();

  // state
  const [Zoom, setZoom] = useState(0);
  const [Center, setCenter] = useState(null);
  const [isClickMarker, setIsClickMarker] = useState(false);
  const [isMapInitialized, setIsMapInitialized] = useState(false);

  // handle click to parent
  const handleClickMarker = (index) => {
    props.onClick(index)
    setIsClickMarker(index)
  }

  // loaded maps
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
  });

  // render and condition
  useEffect(() => {
    if (isLoaded && !isMapInitialized) {
      let bounds
      const workerId = searchParams.get("worker_id");

      if (props.store && props.store.length > 0 && workerId) {
        const markers = props.response.workers;
        bounds = new window.google.maps.LatLngBounds()

        for (let marker of markers) {
          bounds.extend({
            lat: marker.latitude,
            lng: marker.longitude
          });
        }

        setCenter(bounds.getCenter());
        setZoom(18);
        setIsMapInitialized(true);
      } else {
        const markers = [...props.response.workers, props.response.customer];
        const resultMarkers = markers.filter(res => res.latitude !== 0 || res.longitude !== 0);

        bounds = new window.google.maps.LatLngBounds();
        for (let items of resultMarkers) {
          if (items.latitude !== 0 || items.longitude !== 0) {
            bounds.extend({
              lat: items.latitude,
              lng: items.longitude,
            });
          }
        }

        const newCenter = props.response.customer
          ? new window.google.maps.LatLng(props.response.customer.latitude, props.response.customer.longitude)
          : bounds.getCenter();

        setCenter(newCenter);
        setZoom(16)
        setIsMapInitialized(true);
        return
      }
    }
  }, [isLoaded, props.store, props.response.workers, props.response.customer, searchParams])

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <GoogleMap
        zoom={Zoom}
        center={Center}
        mapContainerClassName="h-screen w-full rounded"
        options={{
          disableDefaultUI: true,
          clickableIcons: true,
        }}
      >
        {props.response?.customer?.latitude && props.response?.customer?.longitude && (
          /* customer marker */
          <Marker
            position={{
              lat: props.response?.customer?.latitude,
              lng: props.response?.customer?.longitude,
            }}
            icon={{
              url: `${window.location.origin}/marker.png`,
              scaledSize: new window.google.maps.Size(53, 53),
            }}
            onClick={() => {
              setZoom(100)
              setCenter({
                lat: props.response?.customer?.latitude,
                lng: props.response?.customer?.longitude,
              })
            }}
          />
        )}

        {Array.isArray(props.store) && props.store.length > 0 &&
          /* store marker */
          props.store.map((e, i) => (
            <OverlayView
              position={{ lat: e.latitude, lng: e.longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              key={i}
            >
              <div className="flex flex-col items-center">
                <img
                  src={`${window.location.origin}/icon-store.svg`}
                  className="w-9 h-9"
                  alt="store icon"
                  onClick={() => {
                    setZoom(22);
                    setCenter({
                      lat: e.latitude,
                      lng: e.longitude,
                    });
                  }}
                />
                <p className="font-bold text-sm text-black-primary">
                  {e.name}
                </p>
              </div>
            </OverlayView>
          ))
        }

        {props.response.workers.map((e, i) => (
          /* worker marker */
          <div key={i}>
            {e.latitude && e.longitude && (
              <OverlayView
                position={{ lat: e.latitude, lng: e.longitude }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  className={`cursor-pointer ${isClickMarker === e.id ? 'marker-bg-red' : 'marker-bg'}`}
                >
                  <img src={e.image}
                    className="rounded-3xl w-11 h-11 object-cover mt-2"
                    alt="kanggo"
                    onClick = { async () => {
                      await setCenter({ lat: e.latitude, lng: e.longitude });
                      await handleClickMarker(e.id)
                    }}
                  />
                </div>
              </OverlayView>
            )}
          </div>
        ))}
      </GoogleMap>
    </>
  );
};

export default NewMap;
