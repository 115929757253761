import PropTypes from 'prop-types';

const Header = ({jenisBangunan, address}) => {

  Header.propTypes = {
    jenisBangunan: PropTypes.string,
    address: PropTypes.string
  }

  return (
    <div className="fixed w-[99vw] h-[65px] top-3 left-2 rounded-2xl bg-white border border-grey-600 shadow-[0px_6px_24px_0px_rgba(0,0,0,0.3)] hidden sm:block">
      <div className="m-3 pl-2 pr-2">
        <p className="font-black text-sm">{jenisBangunan}</p>
        <p className="font-reguler text-xs">
         {address}
        </p>
      </div>
    </div>
  )
}

export default Header