import React from "react";

const SomethingWrong = () => {
  return (
    <div className="flex flex-col justify-center items-center absolute w-full h-full text-center">
      <img src={`${window.location.origin}/error.png`} alt=""/>
      <p className="text-2xl subpixel-antialiased font-bold">
       SOMETHING WRONG
      </p>
    </div>
  );
};

export default SomethingWrong;
