import ApiService from "./api.service";

const trackingService = {
  getData: async function (payload) {
    try {
      const requestData = {
        method: "GET",
        url: `${process.env.REACT_APP_PATH_TRACKING}`,
        params: payload,
      };
      const result = await ApiService.customRequest(requestData);
      return result;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default trackingService;

export { trackingService };
