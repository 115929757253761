import React, { Suspense } from "react";
import Index from "./pages/Index";
import NotFound from "./pages/404";
import SomethingWrong from "./pages/SomethingWrong";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  const routes = [
    {
      path: "/",
      element: (
        <Suspense fallback={<h1>Loading....</h1>}>
          <Index/>
        </Suspense>
      ),
    },
    {
      path: "/error",
      element: (
        <Suspense fallback={<h1>Loading....</h1>}>
          <SomethingWrong />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<h1>Loading....</h1>}>
          <NotFound />
        </Suspense>
      ),
    },
  ];

  return (
    <Suspense fallback={null}>
      <Router>
        <Routes>
          {routes.map((e, i) => (
            <Route key={i} {...e} />
          ))}
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
