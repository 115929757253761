import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center absolute w-full h-full text-center">
      <img src={`${window.location.origin}/404.png`} alt=""/>
      <p className="text-2xl subpixel-antialiased font-bold">
        PAGE NOT FOUND
      </p>
    </div>
  );
};

export default NotFound;
