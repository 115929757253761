import axios from "axios";

const ApiService = {
  init(baseUrl) {
    axios.defaults.baseURL = baseUrl;
  },
  get(resource) {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    api.interceptors.response.use(
      (response) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return api.get(resource);
  },
  post(resource, data) {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    api.interceptors.response.use(
      (response) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return api.post(resource, data);
  },
  put(resource, data) {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    api.interceptors.response.use(
      (response) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return api.put(resource, data);
  },
  delete(resource) {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    api.interceptors.response.use(
      (response) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return axios.delete(resource);
  },
  customRequest(data) {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    api.interceptors.response.use(
      (response) => {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return api(data);
  },
};

export default ApiService;
