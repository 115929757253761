import { useState } from "react";
import PropTypes from "prop-types";

const Card = ({ workers }) => {
  const [isVisible, setIsVisible] = useState([]);
  const [expandedCard, setExpandedCards] = useState([]);

  const toggleCard = (id) => {
    setExpandedCards((prevCards) => {
      if (prevCards.includes(id)) {
        return prevCards.filter((cardId) => cardId !== id);
      } else {
        return [...prevCards, id];
      }
    });

    setIsVisible((prevVisibility) => {
      if (prevVisibility.includes(id)) {
        return prevVisibility.filter((cardId) => cardId !== id);
      } else {
        return [...prevVisibility, id];
      }
    });
  };

  const linkForMessage = (workerId) => {
    const path = process.env.REACT_APP_PATH_CHAT;
    const urlQueryParam = `${path}?worker_id=${workerId}`
    window.open(urlQueryParam, "_blank");
  };

  return (
    <div className="flex flex-nowrap justify-start bottom-0 overflow-x-auto scroll-smooth overflow-y-hidden fixed w-screen sm:w-full md:w-full lg:w-fit 2xl:w-fit">
      {workers.map((worker, indexParent) => (
        <div
          className={`flex justify-center m-2
          ${
            !expandedCard.includes(worker.id)
              ? "items-end top-[66.6%] sm:top-[5%] md:top-[74%] lg:top-[79.6%] xl:top-[65%] 2xl:top-[79%] mb-7"
              : "items-start top-[40%] sm:top-10 md:top-[53%] lg:top-[66.6%] xl:top-[45%] 2xl:top-[58%] h-auto mb-8"
          }
            transition-all duration-400 ease-in-out`}
        >
          <div
            key={indexParent}
            className={`flex-shrink-0 divide-y divide-dashed divide-gray-300 md:w-[350px] p-2 rounded-[20px] bg-white
            ${workers.length === 1 ? "w-[95.5vw]" : "w-[85vw]"}
            ${
              !expandedCard.includes(worker.id)
                ? "max-h-[200px]"
                : "max-h-[none]"
            }
            ${
              indexParent === 0
                ? "border border-solid border-red-gradient"
                : "border border-grey-600"
            }
            transition-all duration-500 ease-in-out`}
          >
            {/* HEADER */}
            <div className="flex items-center space-x-5">
              <div className="flex content-center w-3/4 space-x-3">
                <div className="w-24">
                  <img
                    className="top-0 left-0 w-16 h-16 object-cover rounded-full"
                    src={`${worker.image}`}
                    alt="worker-kanggo"
                  />
                </div>
                <div className="w-full">
                  <p className="font-bold text-base text-black-primary">
                    {worker.name}
                  </p>
                  <p className="font-reguler text-sm text-black-primary">
                    {worker.skill_name.slice(0, 16)}
                    {worker.skill_name.length > 16 ? "..." : ""}
                  </p>
                  {worker.is_certified !== 0 ? (
                    <div className="relative mt-1">
                      <img
                        className="w-5 h-5 object-contain"
                        src="/certificated.png"
                        alt="certificated-kanggo"
                      />
                      <span className="absolute top-0.5 left-7 text-xs text-green-600 font-boldItalic">
                        Tukang Bersertifikat
                      </span>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
              <div className="flex justify-end w-3/12 visible lg:invisible xl:invisible 2xl:invisible">
                {/* <div className="flex space-x-4 cursor-pointer">
                  <img
                    src={`${window.location.origin}/call.png`}
                    alt="kanggo"
                    width={30}
                  />
                </div> */}
                <div
                  className="cursor-pointer"
                  onClick={() => linkForMessage(worker.id)}
                >
                  <img
                    src={`${window.location.origin}/message.png`}
                    alt="kanggo"
                    width={30}
                  />
                </div>
              </div>
            </div>

            {/* EQUIPMENT */}
            <div className="mt-2 p-1.5">
              <p className="font-bold text-sm text-gray-500">
                Peralatan dari tukang :
              </p>

              {/* LIST HORIZONTAL */}
              <ul
                className={`${
                  !expandedCard.includes(worker.id) ? "block" : "hidden"
                } flex space-x-2 overflow-x-auto font-reguler text-sm text-gray-400`}
              >
                {worker.tools.slice(0, 4).map((item, index) => (
                  <li
                    className="truncate w-[100px]"
                    key={index}
                  >{`\u2022 ${item}`}</li>
                ))}
              </ul>

              {/* LIST VERTICAL */}
              <ul
                className={`${
                  !expandedCard.includes(worker.id)
                    ? "hidden"
                    : "overflow-y-auto h-44"
                } list-disc list-inside font-reguler text-sm text-gray-400`}
              >
                {worker.tools.map((item, index) => (
                  <li key={index} className="mb-1">
                    {item}
                  </li>
                ))}
              </ul>

              <div className="flex justify-center items-center mt-3.5">
                <button onClick={() => toggleCard(worker.id)}>
                  <div className="flex items-center">
                    <span className="font-bold text-sm text-red-gradient">
                      {!expandedCard.includes(worker.id)
                        ? "Lainnya"
                        : "Sembunyikan"}
                    </span>
                    &nbsp;
                    <img
                      src={`${window.location.origin}/${
                        !expandedCard.includes(worker.id)
                          ? "arrow_right.png"
                          : "arrow_up.png"
                      }`}
                      alt="kanggo"
                      width={20}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Card.propTypes = {
  workers: PropTypes.array,
};

export default Card;
