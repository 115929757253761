import { Player } from '@lottiefiles/react-lottie-player';

const LottiePlayer = ({ src, style }) => {

  return (
    <Player
      autoplay
      loop
      src={src}
      style={style}
    >
    </Player>
  )
}

export default LottiePlayer;